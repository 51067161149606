import React from "react";

const ContactLanding = () => {
  return (
    <div className="pt-10 pb-10 footer contactLanding bg-cover lg:h-landingImageHeight">
      <div className="flex lg:items-center lg:justify-center lg:h-landingHeaderHiehgt">
        <div className="flex flex-col items-center justify-center w-full text-center py-32 lg:py-0">
          <p
            className="text-5xl md:text-7xl lg:text-8xl text-navbarActive font-bold"
            data-aos="fade-up"
          >
            Contact Us!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactLanding;
