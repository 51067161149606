import React from "react";
import WeDo from "../../Images/weDo.png";
const WhatWeDo = () => {
  return (
    <div className="w-full mt-20 mb-20 footer">
      <div
        className="px-3 py-2 bg-navbarBgColor font-bold text-navbarActive w-44 lg:w-44 text-center"
        data-aos="fade-in"
      >
        What We Do
      </div>
      <div className="flex flex-col-reverse mt-2 lg:grid lg:grid-cols-2 lg:gap-3  overflow-x-hidden">
        <div
          className="flex flex-col space-y-4 mt-4 lg:mr-3 md:text-md xl:text-lg"
          data-aos="fade-right"
        >
          <p className="flex text-justify">
            Coal is an important commodity in our everyday life, from generating
            electricity power, steel making, and various industrial processes
            including aluminium, cement, and nickel. We are committed to deliver
            world class operations through our simple, easy, and fast process,
            cutting all unnecessary procedure. Our commitment is to ensure our
            customers get the qualities of indonesian coal they required in an
            easy way, reliable, and secured.
          </p>
          <p className="flex text-justify space-x-2">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-9 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
              </svg>
            </span>
            <span>
              We strictly abide by each and every business policies related to
              our clients including the existing laws and regulations & follow
              only ethical practices.
            </span>
          </p>
          <p className="flex text-justify space-x-2">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-9 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
              </svg>
            </span>
            <span>
              We conduct our business practices with integrity, and embrace
              creativity and latest technological implications in every facet of
              business.
            </span>
          </p>
          <p className="flex text-justify space-x-2">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-9 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
              </svg>
            </span>
            <span>
              We continuously aim to create a friendly working environment,
              which paves way for attaining prosperity, enhance the standard of
              living along with aiding in the overall optimal growth of the
              business.
            </span>
          </p>
        </div>
        <div className="w-full" data-aos="fade-left">
          <img src={WeDo} alt="What we DO" className="mx-auto" />
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
