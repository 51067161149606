import React from "react";

function Location() {
  return (
    <div className="w-full pt-10 pb-10 footer">
      <div className="flex flex-col space-y-5 mt-3  lg:grid lg:grid-cols-2 lg:gap-3 lg:space-y-0  overflow-x-hidden">
        <iframe
          className="w-full h-full rounded"
          title="Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29982.813507384595!2d78.93506152261038!3d20.056653988093426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd31eea31e24bf1%3A0x64115081c258a472!2sWani%2C%20Maharashtra%20445304!5e0!3m2!1sen!2sin!4v1645037911743!5m2!1sen!2sin"
          loading="lazy"
          data-aos="fade-right"
        ></iframe>
        <div className="flex flex-col space-y-4 lg:space-y-8">
          <div
            className="px-3 py-2 bg-navbarBgColor font-bold text-navbarActive w-32 lg:w-44 text-center ml-auto"
            data-aos="fade-in"
          >
            Location
          </div>
          <div className="flex flex-col justify-end items-end space-y-5 lg:space-y-7">
            <p
              className="text-xl lg:text-3xl text-navbarBgColor font-bold pb-1 border-b-4 border-navbarActive"
              data-aos="fade-left"
            >
              Khurana Coal Depo
            </p>
            <p className="flex space-x-2" data-aos="fade-left">
              <div className="flex flex-col space-y-1 items-end lg:text-lg text-right">
                <span>Satighat Road, Rangaripura Wani, Yavatmal,</span>{" "}
                <span>Wani-445304, Maharashtra, India</span>
              </div>
              <span className="p-1 h-8 my-auto bg-navbarBgColor text-navbarActive rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </p>
            <p className="flex space-x-2" data-aos="fade-left">
              <div className="flex flex-col space-y-1 lg:text-lg">
                contact.khuranacoal@gmail.com
              </div>
              <span className="p-1 bg-navbarBgColor text-navbarActive rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </span>
            </p>
            <p className="flex space-x-2" data-aos="fade-left">
              <div className="flex flex-col space-y-1 text-lg">
                +91 95556 76209
              </div>
              <span className="p-1 bg-navbarBgColor text-navbarActive rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
              </span>
            </p>
          </div>
          <a
            href="tel:9555676209"
            data-aos="fade-up"
            className="px-3 py-2 font-bold bg-navbarBgColor text-navbarActive w-44 lg:w-44 text-center rounded mx-auto cursor-pointer hover:shadow-xl hover:text-navbarActive no-underline hover:no-underline decoration-navbarActive"
          >
            Contact Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default Location;
