//check for email.
const isEmail = (email: string) => {
  var isBoolean: boolean;

  const regexp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  isBoolean = regexp.test(email);

  return isBoolean;
};

export const validateForm = ({ name, email, mobile, message }: any) => {
  const re = /^[0-9\b]+$/;
  const mobileReg = /[7-9]\d{9}/;
  let errors: any = {};
  if (name === "") {
    errors.name = "Name cannot be empty!";
  } else if (name.length < 3) {
    errors.name = "Name must be at least 3 characters long!";
  } else if (name.length > 15) {
    errors.name = "Name must be less than 15 characters long!";
  } else if (email === "") {
    errors.email = "Email cannot be empty!";
  } else if (!isEmail(email)) {
    errors.email = "Email is not valid!";
  } else if (mobile === "") {
    errors.mobile = "Mobile cannot be empty!";
  } else if (!mobileReg.test(mobile)) {
    errors.mobile = "Mobile is not valid!";
  } else if (mobile.length !== 10) {
    errors.mobile = "Mobile must be 10 digits long!";
  } else if (message === "") {
    errors.message = "Message cannot be empty!";
  }
  return errors;
};
