import React from "react";
import CountDown from "../CountDown";
import AboutCompany from "./AboutCompany";
import Ourapproach from "./Ourapproach";
import AboutLanding from "./AboutLanding";
import AboutGallery from "./AboutGallery";
import AboutMore from "./AboutMore";
const About = () => {
  return (
    <>
      <AboutLanding />
      <AboutCompany />
      <Ourapproach />
      <AboutGallery />
      <AboutMore />
    </>
  );
};

export default About;
