import React from "react";

const ServicesLanding = () => {
  return (
    <>
      <div className="h-aboutLandingImage lg:h-landingImageHeight bg-center bg-cover serviceLanding"></div>
      <div
        className="pt-20 pb-20 footer serviceLandingText"
        data-aos="fade-down"
      >
        Khurana Coal introduces itself as a bulk provider of a good variety of
        coal. we have a tendency to area unit one dependable name once it
        involves activity high-quality coal. we have a tendency to area unit
        engaged in the line of work to the distributed desires of varied
        industrial sectors throughout all components of Asian nation covering
        Cement Plants, Power Generation Plants, Steel Mills, etc wherever coal
        needs area unit in demand.
      </div>
    </>
  );
};

export default ServicesLanding;
