import React from "react";

const statistics = [
  {
    number: "12+",
    desc: "Years Of Experience",
  },
  {
    number: "60+",
    desc: "Satisfied Clients",
  },
  {
    number: "500+",
    desc: "Location Present",
  },
  {
    number: "5",
    desc: "Assured Quality",
  },
];

const Statistics = () => {
  return (
    <div className="mt-20 mb-20 footer">
      <div className="flex justify-center items-center " data-aos="fade-in">
        <span className="px-3 py-2 bg-navbarBgColor text-navbarActive w-32 lg:w-44 text-center font-bold">
          Statistics
        </span>
      </div>
      <div className="grid grid-cols-2 gap-3 lg:grid-cols-4 lg:gap-11 mt-5">
        {statistics.map((statistic: any, key: number) => {
          if (statistic.number === "5") {
            return (
              <div
                className="grid grid-cols-2 gap-2 bg-navbarBgColor cursor-pointer rounded  hover:shadow-xl hover:shadow-gray-400 statistics"
                key={key}
                data-aos="fade-up"
              >
                <span className="text-white flex text-md justify-center items-center">
                  <span className="text-white font-bold text-2xl lg:text-4xl xl:text-5xl  mr-1">
                    {statistic.number}
                  </span>{" "}
                  star
                </span>
                <span className="text-navbarActive text-xs md:text-md xl:text-lg my-auto">
                  {statistic.desc}
                </span>
              </div>
            );
          } else {
            return (
              <div
                className="grid grid-cols-2 gap-2 bg-navbarBgColor cursor-pointer rounded hover:shadow-xl hover:shadow-gray-400 statistics"
                key={key}
                data-aos="fade-up"
              >
                <span className="text-white text-2xl font-bold lg:text-4xl xl:text-5xl text-center my-auto">
                  {statistic.number}
                </span>
                <span className="text-navbarActive text-xs md:text-md xl:text-lg my-auto">
                  {statistic.desc}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
export default Statistics;
