import React from "react";
import ContactLanding from "./ContactLanding";
import ContactMap from "./ContactMap";
import ContactFrom from "./ContactForm";
const Contact = () => {
  return (
    <>
      <ContactLanding />
      <ContactMap />
      <ContactFrom />
    </>
  );
};

export default Contact;
