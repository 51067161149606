import React, { useEffect } from "react";

const Landing = () => {
  return (
    <div className="pt-10 pb-10 footer landing bg-cover lg:h-landingImageHeight">
      <div className="flex lg:items-center lg:justify-center lg:h-landingHeaderHiehgt">
        <div className="flex flex-col items-center justify-center w-full text-center py-32 lg:py-0">
          <p
            className="text-3xl md:text-4xl lg:text-6xl text-white  flex space-x-3"
            data-aos="fade-down"
          >
            <span className="font-bold">
              Welcome <span className="font-thin">To</span>
            </span>
          </p>
          <p
            className="text-5xl md:text-7xl lg:text-8xl text-navbarActive font-bold"
            data-aos="fade-up"
          >
            Khurana Coal
          </p>
        </div>
      </div>
    </div>
  );
};

export default Landing;
