import React from "react";
import WhatWeDo from "./WhatWeDo";
import Features from "./Features";
import Statistics from "./Statistics";
import AboutUs from "./AboutUs";
import Location from "./Location";
import ImageGallery from "./Gallery";
import Services from "./Services";
import Landing from "./Landing";
// import { ReactComponent as BannerImage } from "../../Images/homePageBanner.svg";
// import Banner from "../../Images/homePageBanner.svg";
const Home = () => {
  return (
    // <div className="block">
    //   {/* <BannerImage /> */}
    //   <img src={Banner} alt="banner" className="h-homeImage w-full" />
    // </div>
    <div className="">
      <Landing />
      <AboutUs />
      <WhatWeDo />
      <Features />
      <Statistics />
      <ImageGallery />
      <Services />
      <Location />
    </div>
  );
};

export default Home;
