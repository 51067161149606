import React from "react";
import Call from "../../Images/Call.svg";
import Expert from "../../Images/Experit.svg";
import Deliver from "../../Images/Deliver.svg";
import Quality from "../../Images/Quality.svg";

const features = [
  {
    title: "Available 24 x 7",
    image: Call,
  },
  {
    title: "Quality Work",
    image: Quality,
  },
  {
    title: "Timely Delivered",
    image: Deliver,
  },
  {
    title: "High Experience",
    image: Expert,
  },
];

const Features = () => {
  return (
    <div className="mt-20 mb-20">
      <div className="feature py-4 lg:py-16  footer  overflow-x-hidden">
        <div className="flex flex-col" data-aos="fade-right">
          <p className="text-2xl lg:text-4xl text-navbarActive font-bold border-b-4 border-white h-fit w-fit pb-1">
            Features
          </p>
        </div>
        <div className="grid grid-cols-2 gap-2 mt-5 lg:grid-cols-4 lg:gap-8 lg:mt-10">
          {features.map((feature: any, key: number) => {
            return (
              <div
                className="border-2 border-white rounded lg:py-4"
                key={key}
                data-aos="fade-down"
              >
                <img
                  src={feature.image}
                  alt="call"
                  className="block mx-auto w-28 h-28"
                />
                <p className="text-white font-bold text-center">
                  {feature.title}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Features;
