import React from "react";

const Ourapproach = () => {
  return (
    <div className="pt-5 pb-5 footer bg-gray-100">
      <div className="flex flex-col-reverse lg:grid lg:grid-cols-2 lg:space-y-0 lg:gap-4  overflow-x-hidden">
        <div
          className="block ourGoal bg-navbarBgColor rounded-lg text-white p-4 text-justify"
          data-aos="fade-right"
        >
          <span className="text-navbarActive font-medium">
            We are the clear leader
          </span>{" "}
          in providing comprehensive contract coal mining services for
          mine-mouth power generators. We operate coal mines for our customers,
          with partnerships that last for decades. When you partner with us, you
          get the benefit of our commitment to partnership, our deep bench of
          experts, and our award-winning employee-based safety culture. We know
          how to maximize production with long-term solutions in a
          cost-effective manner—all with a deep-rooted respect for the land and
          the environment. Our forward-thinking perspective can provide you with
          long-term success.
        </div>
        <div className="flex flex-col gap-4 ml-auto" data-aos="fade-left">
          <p className="text-navbarActive font-bold ml-auto lg:text-xl">
            Our Approach
          </p>
          <p className="text-xl flex text-right w-3/4 font-bold ml-auto md:w-1/2 md:text-3xl lg:text-3xl xl:text-5xl spacing lg:w-3/4 xl:text-right">
            Long-Term Partners With Outstanding Expertise
          </p>
        </div>
      </div>
    </div>
  );
};

export default Ourapproach;
