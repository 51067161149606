import React, { useState, useEffect } from "react";
import NavigationBar from "./components/navbar/NavigationBar";
// import HomePage from "./components/HomePage/Home";
import HomePage from "./components/HomePage/Home";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Services from "./components/Services/Services";
import { Routes, Route } from "react-router-dom";

import Footer from "./components/Footer/Footer";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      delay: 100,
      once: true,
    });
  }, []);
  const [activeClass, setActiveClass] = useState<string>("");
  return (
    <div className="flex flex-col justify-between h-screen">
      <NavigationBar
        activeClass={activeClass}
        setActiveClass={setActiveClass}
      />
      <div className="pt-16">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="about" element={<About />} /> */}
        </Routes>
      </div>

      <Footer activeClass={activeClass} setActiveClass={setActiveClass} />
    </div>
  );
}

export default App;
