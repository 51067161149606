import React from "react";

const ContactMap = () => {
  return (
    <div className="py-5 footer">
      <div className="w-full h-contactMapHeight" data-aos="fade-down">
        <iframe
          className="w-full h-full rounded"
          title="Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29982.813507384595!2d78.93506152261038!3d20.056653988093426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd31eea31e24bf1%3A0x64115081c258a472!2sWani%2C%20Maharashtra%20445304!5e0!3m2!1sen!2sin!4v1645037911743!5m2!1sen!2sin"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactMap;
