import React, { useEffect } from "react";
import "./service.css";

const servicesProvided = [
  {
    title: "Indonesian Coal",
    description:
      "We conjointly import and provide high-quality Indonesian coal.",
    number: "01",
    id: "1",
  },
  {
    title: "Indian Coal ",
    description:
      "We area unit one in all the premier Indian Coal Distributors within the Nation.",
    number: "02",
    id: "2",
  },
  {
    title: "Screen Coal",
    description:
      "We have our own screening plant that assures the simplest quality of Screening Coal.",
    number: "03",
    id: "3",
  },
  {
    title: "White Coal",
    description:
      "We area unit one in all the premier Indian Coal Distributors of White Coal.",
    number: "04",
    id: "4",
  },
];

const ServicesProvided = () => {
  useEffect(() => {
    let i = "1";
    let z = "1";
    const interval = setInterval(() => {
      if (i === "5") {
        i = "1";
        z = "4";
      } else {
        console.log(i);

        const service = document.getElementById(z);
        console.log(service);
        service?.classList.remove("animationDiv");

        const services = document.getElementById(i);
        console.log(services);
        services?.classList.add("animationDiv");
        let increment = parseInt(i) + 1;
        i = increment.toString();
        if (i === "1") {
          z = "1";
          z = "4";
        } else {
          let decrement = parseInt(i) - 1;
          z = decrement.toString();
        }
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="w-full">
      <div className="footer">
        <div className="px-3 py-2 bg-navbarBgColor font-bold text-navbarActive w-44 lg:w-44 text-center block mx-auto">
          Services Provided
        </div>
      </div>
      <div className="pt-5 pb-5 footer bg-gray-200 mt-4  overflow-x-hidden">
        <div className="container">
          <div className="timeline">
            <ul>
              {servicesProvided.map((service: any, key: number) => {
                return (
                  <li
                    className="group flex space-x-4 hover:bg-navbarBgColor cursor-pointer"
                    key={key}
                    id={service.id}
                  >
                    <div className="flex flex-col space-y-3 w-11/12">
                      <h1 className="text-lg lg:text-2xl font-bold text-navbarBgColor group-hover:text-navbarActive">
                        {service.title}
                      </h1>
                      <p
                        className="text-sm lg:text-md lg:w-3/4 group-hover:text-white"
                        data-aos={
                          service.id === "1" || service.id === "3"
                            ? "fade-right"
                            : "fade-left"
                        }
                      >
                        {service.description}
                      </p>
                    </div>
                    <span className="bg-navbarBgColor text-navbarActive rounded h-fit p-2 font-bold group-hover:bg-navbarActive group-hover:text-navbarBgColor">
                      {service.number}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesProvided;
