import React, { useState, Dispatch, SetStateAction } from "react";
import classNames from "classnames";
import NavLogo from "../../Images/navLogo.svg";
import "./navigation.css";
import { Link } from "react-router-dom";
const menuItems = [
  {
    name: "Home",
    id: 1,
    url: "",
  },
  {
    name: "About Us",
    id: 2,
    url: "about",
  },
  {
    name: "Our Services",
    id: 3,
    url: `services`,
  },
  {
    name: "Contact Us",
    id: 4,
    url: `contact`,
  },
];

interface Props {
  activeClass: string;
  setActiveClass: Dispatch<SetStateAction<string>>;
}

const NavigationBar = ({ activeClass, setActiveClass }: Props) => {
  const [openNavbar, setOpenNavbar] = useState<boolean>(false);
  const clickableevent = (key: string) => {
    setOpenNavbar(!openNavbar);
    setActiveClass(key);
  };

  // const [activeClass, setActiveClass] = useState<string>("");
  return (
    <div className="w-full bg-navbarBgColor fixed z-50">
      <div className="lg:mx-20 p-3 flex justify-between">
        {/* Logo */}
        <Link
          to="/"
          className="text-white font-bold my-auto"
          onClick={() => clickableevent("")}
        >
          <img
            src={NavLogo}
            alt="logo"
            className="h-8 md:h-10 cursor-pointer"
          />
        </Link>

        {/* Menu Items */}
        {/* <div className="flex space-x-10"> */}
        <div className="hidden lg:flex lg:space-x-10">
          {menuItems.map((item: any, key: number) => {
            return (
              <div className="flex flex-col" key={key}>
                <Link
                  to={`/${item.url}`}
                  key={key}
                  onClick={() => clickableevent(item.url)}
                  className="group text-white list-none p-2 hover:no-underline hover:bg-sliderColor hover:rounded cursor-pointer focus:text-navbarActive decoration-navbarActive"
                >
                  <span
                    className={
                      activeClass === item.url
                        ? `text-navbarActive font-bold hover:no-underline hover:text-navbarActive decoration-navbarActive focus:text-navbarActive`
                        : "  group-hover:text-navbarActive font-medium cursor-pointer  hover:no-underline focus:text-navbarActive decoration-navbarActive"
                    }
                  >
                    {item.name}
                  </span>
                  {/* {activeClass === item.url && (
                    <p className="transform h-2 w-2 rounded-full bg-white mx-auto mt-1 transition-all ease-out duration-300"></p>
                  )} */}
                </Link>
              </div>
            );
          })}
        </div>
        <div className="p-1">
          <a
            href="tel:9555676209"
            className="bg-navbarActive text-black rounded text-center p-1 font-normal w-32 hidden lg:block hover:text-navbarBgColor no-underline hover:no-underline decoration-navbarBgColor"
          >
            Call Now
          </a>
        </div>
        {/* </div> */}

        {/* Mobile Icons */}
        <div className="flex lg:hidden">
          <div className="p-1">
            <a
              href="tel:9555676209"
              className="bg-navbarActive text-black rounded text-center  font-normal px-3 py-1 block lg:hidden focus:outline-none focus:no-underline hover:text-navbarBgColor no-underline hover:no-underline decoration-navbarBgColor"
            >
              Call Now
            </a>
          </div>
          <div
            className="text-white font-bold lg:hidden cursor-pointer hover:bg-sliderColor hover:rounded p-2"
            onClick={() => setOpenNavbar(!openNavbar)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          openNavbar ? "translate-x-0" : "-translate-x-full",
          "transform z-10 absolute w-full bg-navbarBgColor  py-1 lg:hidden transition ease-in-out delay-150 "
        )}
      >
        {menuItems.map((item: any, key: number) => {
          return (
            <div key={key}>
              <Link
                to={`/${item.url}`}
                key={key}
                onClick={() => setOpenNavbar(!openNavbar)}
                className="group flex text-white list-none px-2 py-3 hover:no-underline hover:bg-sliderColor cursor-pointer border-b-2 border-white last-of-type:border-0"
              >
                <span
                  onClick={() => clickableevent(item.url)}
                  className={
                    activeClass === item.url
                      ? `text-navbarActive font-bold hover:no-underline hover:text-navbarActive focus:text-navbarActive decoration-navbarActive px-2`
                      : " group-hover:text-navbarActive group-hover:font-medium font-thin cursor-pointer hover:no-underline text-lg px-2"
                  }
                >
                  {item.name}
                </span>
                {/* {activeClass === item.url && (
                  <p className="h-2 w-2 rounded-full bg-white my-auto"></p>
                )} */}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NavigationBar;
/*
<div className="flex">
  <nav className="flex w-full bg-white justify-around items-center pl-5 pr-5 min-h-navigationHeight">
    <div className="text-lg text-black">Sameep Sawant</div>
    <ul
      className={
        classNames(showactive  ? "translate-x-0" : "-translate-x-full",,"block transform lg:flex absolute left-0 p-10 text-white w-full z-10 top-navigationTop ease-in-out duration-150"),
        
      }
    >
      {menuItems.map((item: any, key: number) => (
        <li
          key={item.id}
          onClick={() => setShowActive(!showactive)}
          className="text-green-600 list-none"
        >
          <a
            onClick={() => clickableevent(key)}
            href={`#${item.url}`}
            className={
              activeclass === key
                ? `text-gray-400 font-bold hover:no-underline`
                : " hover:text-greenTextColor hover:font-medium font-thin cursor-pointer hover:no-underline"
            }
          >
            {item.name}
          </a>
        </li>
      ))}

    </ul>

    <p className=" cursor-pointer">
      <i
        className=" fas fa-bars block lg:hidden text-black"
        onClick={() => setShowActive(!showactive)}
      />
    </p>
  </nav>
</div>;
*/
