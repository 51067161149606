import React from "react";
import CountDown from "../CountDown";
import ServiceLanding from "./ServicesLanding";
import ServiceProvided from "./ServicesProvided";
const Services = () => {
  return (
    <>
      <ServiceLanding />
      <ServiceProvided />
    </>
  );
};

export default Services;
