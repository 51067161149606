import React from "react";
import aboutCompany from "../../Images/aboutCompany.png";
const AboutCompany = () => {
  return (
    <div className="footer pt-10 pb-5">
      <div
        className="px-3 py-2 bg-navbarBgColor font-bold text-navbarActive w-44 lg:w-44 text-center block lg:hidden"
        data-aos="fade-in"
      >
        About Company
      </div>
      <div className="flex flex-col-reverse spcae-y-4 lg:grid lg:grid-cols-2 lg:gap-8 lg:space-y-0 mt-2 lg:mt-0  overflow-x-hidden">
        <div
          className="flex flex-col space-y-3 xl:space-y-6"
          data-aos="fade-right"
        >
          <div className="px-3 py-2 bg-navbarBgColor font-bold text-navbarActive w-44 lg:w-44 text-center hidden lg:block">
            About Company
          </div>
          <div className="text-justify md:text-lg lg:text-sm xl:text-xl">
            Khurana Coal Depo was founded by Khuranas with the aim of making it
            one of the biggest coal companies in India. Headquartered at Wani,
            Maharashtra; the company is a diversified multi-location
            conglomerate with special interests related to Coal, Power,
            Warehouse, etc.
          </div>
          <div className="text-justify md:text-lg lg:text-sm xl:text-xl">
            The constantly driving passion, an urge to excel, the in-built
            ability to strive, and the power to challenge worldwide competitors
            saw the transformation of a micro-enterprise into a national entity
            and pave its way to global success as the leading coal suppliers.
          </div>
          <div className="px-3 py-2 bg-white border-2 border-navbarBgColor rounded font-bold text-navbarActive w-44 lg:w-44 text-center">
            Contact Us
          </div>
        </div>
        <div className="lg:w-full lg:h-full" data-aos="fade-left">
          <img src={aboutCompany} alt="aboutCompany" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
