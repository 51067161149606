import React from "react";

const AboutMore = () => {
  return (
    <div className="pt-5">
      <div className="footer">
        <div
          className="px-3 py-2 bg-navbarBgColor font-bold text-navbarActive w-44 lg:w-44 text-center block mx-auto"
          data-aos="fade-in"
        >
          More About Us
        </div>
      </div>
      <div className="pt-5 pb-5 footer bg-gray-200 mt-5">
        <div
          className="flex flex-col space-y-3 lg:grid lg:grid-cols-2 lg:gap-8 lg:space-y-0"
          data-aos="fade-down"
        >
          <div className="group p-3 flex flex-col space-y-3  rounded-lg shadow-xl xl:p-6 our cursor-pointer ">
            <p className="text-lg text-navbarActive lg:text-black group-hover:text-navbarActive font-bold">
              Our Moto
            </p>
            <p className="text-md ourText group-hover:text-white text-justify h-moreAboutUs md:h-moreAboutUsMd sm:h-moreAboutUsMd lg:h-auto">
              We Plan, We Strive, We Achieve. This motto has been followed by
              the company since its inception and has been key to its success.
            </p>
          </div>
          <div className="group p-3 flex flex-col space-y-3 rounded-lg shadow-xl border-2 our coursor-pointer">
            <p className="text-lg text-navbarActive lg:text-black group-hover:text-navbarActive font-bold">
              Our Vision
            </p>
            <p className="text-md ourText group-hover:text-white text-justify h-moreAboutUs sm:h-moreAboutUsMd md:h-moreAboutUsMd lg:h-auto">
              We at Khurana understand that nothing is constant and have always
              had a dynamic approach to our work. Our quick shift to foreign
              coal to keep up with increasing demands nationwide was one of the
              key moves that highlight this aspect of our behavior.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMore;
