import React, { useState } from "react";
import axios from "axios";
import { validateForm } from "../../Utils/Validation";
// https://sheet.best/api/sheets/94d7d7e9-77d3-4f3d-9365-a7fde767280b
const ContactForm = () => {
  const [contactInfo, setContactInfo] = useState<any>({
    name: "",
    email: "",
    message: "",
    mobile: "",
  });
  const [errors, setErrors] = useState<any>({
    name: "",
    email: "",
    message: "",
    mobile: "",
  });

  const updateContactForm = (name: string, value: any) => {
    setContactInfo({ ...contactInfo, [name]: value });
    const values = { ...contactInfo, [name]: value };
    setErrors(validateForm(values));
  };

  const isError = () => {
    let errorStatus = false;
    Object.keys(errors).map((key: any, index: any) => {
      if (errors[key]) {
        errorStatus = true;
      } else {
        errorStatus = false;
      }
    });

    return errorStatus;
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    if (!isError()) {
      axios
        .post(
          "https://sheet.best/api/sheets/94d7d7e9-77d3-4f3d-9365-a7fde767280b",
          {
            Name: contactInfo.name,
            Email: contactInfo.email,
            Message: contactInfo.message,
            Mobile: contactInfo.mobile,
          }
        )
        .then((res) => {
          console.log(res);
          console.log(contactInfo);
          setContactInfo({
            name: "",
            email: "",
            message: "",
            mobile: "",
          });
          setErrors({
            name: "",
            email: "",
            message: "",
            mobile: "",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="footer">
        <div
          className="px-3 py-2 bg-navbarBgColor font-bold text-navbarActive w-32 lg:w-44 text-center mx-auto"
          data-aos="fade-up"
        >
          Contact Us
        </div>
      </div>
      <div className=" footer bg-gray-100 py-10 mt-5 overflow-x-hidden">
        <div className="flex flex-col space-y-5 lg:grid lg:grid-cols-2 lg:gap-8 lg:space-y-0 ">
          <div
            className="bg-white border-2 shadow-lg contactInfo lg:p-5"
            data-aos="fade-right"
          >
            <div className="relative font-bold text-md lg:text-xl cForm">
              We’re Waiting to Hear From You
            </div>
            <div className="mt-10">
              <form onSubmit={(e) => submitForm(e)}>
                <div className="flex flex-col space-y-4 xl:grid xl:grid-cols-2 xl:space-y-0 xl:gap-4">
                  <div className="flex flex-col space-y-4">
                    <label className="font-bold flex space-x-2">
                      Name<span className="text-red-600">*</span>
                    </label>
                    <input
                      id="name"
                      name="name"
                      value={contactInfo.name}
                      onChange={(e) =>
                        updateContactForm(e.target.name, e.target.value)
                      }
                      className="border-b-2 border-black m-0 p-0 focus:outline-none"
                      placeholder="Enter your name"
                    />
                    <div className="text-md text-red-600">{errors.name}</div>
                  </div>
                  <div className="flex flex-col space-y-4">
                    <label className="font-bold flex space-x-2">
                      Email<span className="text-red-600">*</span>
                    </label>
                    <input
                      id="email"
                      name="email"
                      value={contactInfo.email}
                      onChange={(e) =>
                        updateContactForm(e.target.name, e.target.value)
                      }
                      className="border-b-2 border-black m-0 p-0 focus:outline-none"
                      placeholder="Enter your email"
                    />
                    <div className="text-md text-red-600">{errors.email}</div>
                  </div>
                </div>
                <div className="flex flex-col w-full mt-3 xl:w-1/2">
                  <label className="font-bold flex space-x-2">
                    Mobile<span className="text-red-600">*</span>
                  </label>
                  <input
                    id="phone"
                    name="mobile"
                    value={contactInfo.mobile}
                    onChange={(e) =>
                      updateContactForm(e.target.name, e.target.value)
                    }
                    className="border-b-2 border-black m-0 p-0 focus:outline-none"
                    placeholder="Enter your mobile number"
                  />
                  <div className="text-md text-red-600">{errors.mobile}</div>
                </div>
                <div className="flex flex-col w-full mt-3">
                  <label className="font-bold flex space-x-2">
                    Message<span className="text-red-600">*</span>
                  </label>
                  <input
                    id="Message"
                    name="message"
                    value={contactInfo.message}
                    onChange={(e) =>
                      updateContactForm(e.target.name, e.target.value)
                    }
                    className="border-b-2 h-20 placeholder:m-0 border-black m-0 p-0 focus:outline-none"
                    placeholder="Enter your message"
                  />
                  <div className="text-md text-red-600">{errors.message}</div>
                </div>
                <div className="w-full mt-3">
                  <button
                    type="submit"
                    className="text-center bg-navbarBgColor text-navbarActive block mx-auto px-3 py-2 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="bg-navbarBgColor contactInfo" data-aos="fade-left">
            <div className="flex flex-col justify-start items-end space-y-5 lg:space-y-10">
              <p className="text-xl lg:text-3xl text-white font-bold pb-1 border-b-4 border-navbarActive">
                Khurana Coal Depo
              </p>
              <p className="flex space-x-2">
                <div className="flex flex-col space-y-1 items-end text-sm text-right md:text-lg text-white">
                  <span>Satighat Road, Rangaripura Wani, Yavatmal,</span>{" "}
                  <span>Wani-445304, Maharashtra, India</span>
                </div>
                <span className="p-1 h-6 my-auto bg-navbarBgColor text-navbarActive rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </p>
              <p className="flex space-x-2">
                <div className="flex flex-col mt-1 text-sm space-y-1 md:text-lg text-white w-full">
                  contact.khuranacoal@gmail.com
                </div>
                <span className="p-1 h-6 bg-navbarBgColor text-navbarActive rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </span>
              </p>
              <p className="flex space-x-2">
                <div className="flex flex-col mt-2 space-y-1 text-sm md:text-lg text-white">
                  +91 95556 76209
                </div>
                <span className="p-1 h-6 bg-navbarBgColor text-navbarActive rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                </span>
              </p>
            </div>
            <a
              href="tel:9555676209"
              className="decoration-none focus:no-underline focus:text-navbarActive"
            >
              <div className="p-3  callBtn mx-auto bg-callNowColor shadow-xl text-navbarActive rounded-xl cursor-pointer w-44 text-center font-bold focus:no-underline focus:text-navbarActiv">
                Call Now
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
