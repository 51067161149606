import React from "react";

const services = [
  {
    title: "Indonesian Coal Supply",
    description: "We also import and supply high-quality Indonesian coal.",
    number: "01",
  },
  {
    title: "Indian Coal Supply",
    description:
      "We are one of the premier Indian coal distributors in the nation.",
    number: "02",
  },
  {
    title: "Screen Coal Supply",
    description:
      "We have our own screen plant which assures best quality of screen coal.",
    number: "03",
  },
  {
    title: "White Coal Supply",
    description:
      "We are one of the premier Indian coal distributors of White Coal.",
    number: "04",
  },
];

const Services = () => {
  return (
    <div className="pt-10 pb-10 footer bg-gray-100">
      <div className="flex flex-col space-y-3 mt-3 lg:grid lg:grid-cols-2 lg:gap-4 lg:space-y-0  overflow-x-hidden">
        <div className="flex flex-col space-y-3">
          <div
            className="px-3 py-2 bg-navbarBgColor  text-navbarActive w-32 lg:w-44 text-center font-bold"
            data-aos="fade-in"
          >
            Services
          </div>
          <p className="font-bold text-2xl" data-aos="fade-right">
            That we offer to you
          </p>
        </div>
        <p className="text-justify font-normal" data-aos="fade-left">
          Coal is an important commodity in our everyday life, from generating
          electricity power, steel making, and various industrial processes
          including aluminium, cement, and nickel. We are committed to deliver
          world class operations through our simple, easy, and fast process,
          cutting all unnecessary procedure.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-5 lg:grid-cols-4">
        {services.map((service: any, key: number) => {
          return (
            <div
              key={key}
              data-aos="fade-down"
              className="group flex flex-col space-y-5 bg-navbarBgColor lg:bg-white hover:bg-gradient-to-r from-navbarBgColor to-navbarBgColor rounded-lg p-4 cursor-pointer hover:shadow-xl hover:shadow-gray-300"
            >
              <p className="font-bold text-lg text-navbarActive lg:text-black group-hover:text-navbarActive servicesTitle">
                {service.title}
              </p>
              <p className="font-normal services  group-hover:text-white">
                {service.description}
              </p>
              <span className="bg-navbarActive lg:bg-navbarBgColor group-hover:bg-navbarActive group-hover:text-navbarBgColor group-hover:font-bold w-fit text-navbarBgColor lg:text-navbarActive px-2 py-1 my-auto">
                {service.number}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Services;
