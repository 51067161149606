import React from "react";
import WeDo from "../../Images/aboutUs.png";

const AboutUs = () => {
  return (
    <div className="w-full pt-10 pb-10 footer">
      <div
        className="px-3 py-2 bg-navbarBgColor font-bold text-navbarActive w-32 lg:w-44 text-center ml-auto"
        data-aos="fade-in"
      >
        About Us
      </div>
      <div className="flex flex-col mt-2 lg:grid lg:grid-cols-2 lg:gap-5  overflow-x-hidden">
        <div className="w-full" data-aos="fade-right">
          <img src={WeDo} alt="What we DO" className="mx-auto" />
        </div>
        <div
          className="flex flex-col space-y-5 mt-4 md:text-md xl:text-lg lg:ml-2"
          data-aos="fade-left"
        >
          <p className="flex text-justify">
            <span>
              <span className="font-bold">Khurana Coal</span> is an Indian
              coal-based company in Wani, Maharashtra. Initially focused on the
              energy segment, with our expertise in Indonesian coal. We are
              responsible for moving and fulfilling the world's energy needs. We
              have business roots in coal since 2012, We have also started
              different firms for expansion of our business in different states.
            </span>
          </p>
          <p className="flex text-justify">
            The best working team of professionals and years of competence in
            the allied field of interest is what made us to flourish our coal
            business all over the nation and made us leading coal traders in the
            country.
          </p>
          <p className="flex text-justify">
            The firm was formed to capitalize on the emerging opportunities in
            the rapidly growing energy sector in India. Our ethical approach and
            use of the latest technical procedures have enabled us to garner a
            huge client base in the national market and create a prominent place
            among each and every customer of India. It was not a facile route to
            success for us to craft our unique identity and emerge as one of the
            unparalleled coal industries in India.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
