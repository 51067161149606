import React, { Dispatch, SetStateAction } from "react";
import NavLogo from "../../Images/navLogo.svg";
import ScrollToTop from "react-scroll-to-top";
import "../../App.css";
import { Link } from "react-router-dom";
const quickLinks = [
  {
    linkName: "Home",
    link: "",
  },
  {
    linkName: "About Us",
    link: "about",
  },
  {
    linkName: "Our Services",
    link: "services",
  },
  {
    linkName: "Contact Us",
    link: "contact",
  },
];

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 15l7-7 7 7"
      />
    </svg>
  );
};

interface Props {
  activeClass: string;
  setActiveClass: Dispatch<SetStateAction<string>>;
}
const Footer = ({ activeClass, setActiveClass }: Props) => {
  return (
    <div className="relative py-5  bg-navbarBgColor footer">
      <div className=" flex flex-col lg:flex-row space-y-5 lg:space-x-5 ">
        <ScrollToTop
          smooth
          className="flex items-center justify-center right-4 bottom-2 fixed w-14 h-14 rounded-full ml-auto bg-navbarActive focus:outline-none"
          top={20}
          component={<Icon />}
        />
        <div className="flex flex-col justify-start items-start">
          <img
            src={NavLogo}
            alt="logo"
            className="h-14 lg:h-16 cursor-pointer"
          />
          <p className="text-white text-sm footerDescription w-full lg:w-9/12 mt-3 text-justify">
            Khurana Coal is an Indian coal-based company in Wani, Maharashtra.
            Initially focused on the energy segment, with our expertise in
            Indonesian coal.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="flex flex-col space-y-3">
            <p className="text-navbarActive text-lg font-bold">Quick Links</p>
            <div className="flex flex-col space-y-3">
              {quickLinks.map((link: any, key: number) => {
                return (
                  <Link
                    key={key}
                    to={`/${link.link}`}
                    onClick={() => setActiveClass(link.link)}
                    className="footerlink text-md font-thin cursor-pointer hover:no-underline  hover:font-bold"
                  >
                    {link.linkName}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="flex text-navbarActive text-lg font-bold">Office</p>
            <p className="text-md text-white font-medium">Khurana Coal Depo</p>
            <p className="text-sm text-white font-thin">
              Satighat Road, Rangaripura Wani, Yavatmal, Wani-445304,
              Maharashtra, India
            </p>
            <p className="text-sm text-white font-thin flex space-x-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
              </span>
              <a
                href="tel:9555676209"
                className="cursor-pointer hover:text-navbarActive no-underline hover:no-underline decoration-navbarActive"
              >
                +91 9555676209
              </a>
            </p>
            <p className="text-sm text-white font-thin flex space-x-2 cursor-pointer">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </span>
              <p className="cursor-pointer hover:text-navbarActive">
                contact.khuranacoal@gmail.com
              </p>
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center text-white font-thin mt-3">
        <span>&copy; Khurana coal 2022, All right reserved</span>
      </div>
    </div>
  );
};

export default Footer;
