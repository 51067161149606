import React from "react";
import Img1 from "../../Images/Image1.png";
import Img2 from "../../Images/Image2.png";
import Img3 from "../../Images/Image3.png";

const ImageGallery = () => {
  return (
    <div className="pt-10 pb-10 footer">
      <div className="flex flex-col space-y-3 lg:grid lg:grid-cols-3 lg:gap-6 lg:space-y-0 lg:mx-20">
        <img src={Img1} alt="image1" className="w-full" />
        <img src={Img2} alt="image2" className="w-full" />
        <img src={Img3} alt="image3" className="w-full" />
      </div>
    </div>
  );
};

export default ImageGallery;
